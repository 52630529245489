import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from '@app/app-config.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subject } from 'rxjs';
import { map, catchError, shareReplay } from 'rxjs/operators';

import { User } from '../models/user.model';

import { ANALYTIC_EVENTS, TrackingService } from './tracking.service';

/**
 * UserService handles all user related tasks,
 * like login, logout, retrieving user data, changing user data, ...
 */
@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user$: Observable<User | null>;

  /**
   * Set to the url, the login should redirect after login or logout
   */
  public redirectUrl!: string;

  // Can be observed for clean-up tasks after logout
  private _logout$ = new Subject<boolean>();
  // Can be observed for clean-up tasks after login
  private _login$ = new Subject<boolean>();

  // Login API base URL
  private apiUrl: string;

  public profileEditUrl: string;

  /**
   * Service Constructor
   * @param http
   * @param translate
   * @param route
   * @param inputSanitizer
   */
  constructor(
    private http: HttpClient,
    private configService: AppConfigService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private trackingService: TrackingService
  ) {
    // configure the Login App URL
    this.apiUrl = this.configService.getConfig().loginUrl;

    this.profileEditUrl = this.apiUrl + '/ciam/profile/edit/*';

    // subscribe to query parameters change to handle login/logout callback
    this.handleLoginCallback();

    this.user$ = this.getUser$();
  }

  /**
   * Returns an observable of the user logout Subject.
   * Subscribe to receive changes.
   *
   * @return {Observable<any>} current user observable
   */
  public get logout$(): Observable<any> {
    return this._logout$.asObservable();
  }

  /**
   * Returns an observable of the user login Subject.
   * Subscribe to receive changes.
   *
   * @return {Observable<any>} current user observable
   */
  public get login$(): Observable<any> {
    return this._login$.asObservable();
  }

  /**
   * Returns an observable of the current log-in state.
   * @return {Observable<boolean>} current log-in state (false => not logged in)
   */
  public isLoggedIn(): Observable<boolean> {
    return this.user$.pipe(map((user) => user !== null));
  }

  /**
   * Returns boolean if the user is logged in
   * Note: this does not properly catch invalid sessions
   */
  public isLoggedInSync(): boolean {
    return !!localStorage?.getItem('loggedIn');
  }

  /**
   * Show the login page (CIAM) via redirect
   */
  public login() {
    this._login$.next(true);
    window.location.href = this.buildLoginAppUrl('/login-user');
  }

  /**
   * Show the register page (CIAM) via redirect
   */
  public register() {
    window.location.href = this.buildLoginAppUrl('/register-user');
  }

  /**
   * Triggers a logout (CIAM) via redirect
   * Clears the current user subject, so subscribed components
   * can handle the logout gracefully (e.g. clear local storage)
   */
  public logout() {
    this._logout$.next(true);
    window.location.href = this.buildLoginAppUrl('/logout-user');
  }

  /**
   * Load user from API
   */
  private getUser$() {
    return this.http.get(`${this.apiUrl}/userinfo`, { withCredentials: true }).pipe(
      map((user: any) => {
        localStorage?.setItem('loggedIn', 'true');
        return new User(user.data);
      }),
      catchError(() => {
        // user is not logged in
        // run cleanup tasks if the user was logged in before
        if (localStorage?.getItem('loggedIn') === 'true') {
          // user was logged in before, but is not anymore -> run cleanup tasks
          this._logout$.next(true);
        }
        localStorage?.removeItem('loggedIn');
        return of(null);
      }),
      shareReplay(1)
    );
  }

  /**
   * Helper function to build the Login App URL
   * with all required query parameters
   * @param path
   */
  private buildLoginAppUrl(path: string): string {
    if (!this.redirectUrl) {
      this.redirectUrl = window.location.toString();
    }
    const queryParams = {
      lang: this.translate.currentLang,
      redirect_url: this.redirectUrl,
    };

    // return the full login url
    return this.apiUrl + path + this.buildQueryParameterString(queryParams);
  }

  /**
   * Helper function to build a query paramter string
   * (Similar to jQuery.params())
   * @param queryParams
   */
  // TODO: replace with modern params feature
  private buildQueryParameterString(queryParams: any): string {
    const esc = encodeURIComponent;
    return (
      '?' +
      Object.keys(queryParams)
        .map((key) => (queryParams[key] ? esc(key) + '=' + esc(queryParams[key]) : null))
        .filter((param) => param) // filter empty params
        .join('&')
    );
  }

  /**
   * Subscribes to query parameters, to see if we navigated here from login or logout
   * possible error handling etc. could happen here
   */
  private handleLoginCallback() {
    this.route.queryParamMap
      .pipe(
        map((params) => {
          return {
            action: params.get('action'),
            authError: params.get('authError'),
            authStatus: params.get('authStatus'),
          };
        })
      )
      .subscribe((loginCallbackParameters) => {
        if (loginCallbackParameters.action) {
          switch (loginCallbackParameters.action) {
            case 'login': {
              this.trackingService.push(location.href, {
                events: ANALYTIC_EVENTS.USER_LOGGED_IN,
              });
              // handle login success/error
              break;
            }
            case 'logout': {
              // handle logout success/error
              break;
            }
          }
          // reset query parameter
          this.router.navigate([], {
            queryParams: { action: null, authError: null, authStatus: null },
            queryParamsHandling: 'merge',
          });
        }
      });
  }
}
