import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
declare global {
  interface Window {
    UC_UI: any;
  }
}
const usercentrics: any = window.UC_UI;

export enum CONSENT_SERVICES {
  ADOBE = 'Adobe Analytics',
  AKAMAI = 'Akamai',
  HERE = 'Here',
  GUEST_MANAGEMENT = 'Guest Management of Press Events',
  LOGIN = 'Mercedes me Login',
  AZURE = 'Microsoft Azure',
  WEBSITE = 'The Me Media Website',
  USERCENTRICS = 'Usercentrics Consent Management Platforms',
  YOUTUBE = 'YouTube Video',
  GTM = 'Google Tag Manager',
  GA4 = 'Google Analytics 4',
}

@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  /**
   * Observable subject for changed consents
   */
  public changedConsent = new Subject<Record<string, boolean>>();

  private _consents = {
    [CONSENT_SERVICES.ADOBE]: false,
    [CONSENT_SERVICES.AKAMAI]: false,
    [CONSENT_SERVICES.AZURE]: false,
    [CONSENT_SERVICES.HERE]: false,
    [CONSENT_SERVICES.GUEST_MANAGEMENT]: false,
    [CONSENT_SERVICES.LOGIN]: false,
    [CONSENT_SERVICES.USERCENTRICS]: false,
    [CONSENT_SERVICES.WEBSITE]: false,
    [CONSENT_SERVICES.YOUTUBE]: false,
    [CONSENT_SERVICES.GTM]: false,
    [CONSENT_SERVICES.GA4]: false,
  };

  private _isInitialized = false;

  constructor(
    private translateService: TranslateService,
    private router: Router
  ) {
    this.loadUserCentricsScript();
  }

  public listenToConsentEvents() {
    window.addEventListener('onConsentStatusChange', (event: any) => {
      if (event.detail?.event === 'consent_status') {
        this.updateConsents(event.detail);
        this.changedConsent.next({ ...this._consents });
      }
    });

    window.addEventListener('usercentrics-initialized', () => {
      // Update UserCentrics language based on the current app language
      this._isInitialized = true;
      this._setUCLang();
    });

    this.translateService.onLangChange.subscribe(() => {
      this._setUCLang();
    });
  }

  private loadUserCentricsScript() {
    if (typeof window.UC_UI === 'undefined') {
      const script = document.createElement('script');
      script.id = 'usercentrics-cmp';
      script.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js';
      script.setAttribute('data-lang', 'auto');
      script.setAttribute('data-version', 'preview');
      script.setAttribute('data-settings-id', 'dDksft6Xb'); // Replace with your actual settings ID
      script.async = true;
      script.onload = () => {
        this.initializeUserCentrics();
      };
      document.head.appendChild(script);
    } else {
      this.initializeUserCentrics();
    }
  }

  private initializeUserCentrics() {
    if (!usercentrics) {
      console.error('UserCentrics script is not loaded.');
      return;
    }

    window.addEventListener('onConsentStatusChange', (event: any) => {
      if (event.detail?.event === 'consent_status') {
        this.updateConsents(event.detail);
        this.changedConsent.next({ ...this._consents });
      }
    });

    window.addEventListener('usercentrics-initialized', () => {
      // Update UserCentrics language based on the current app language
      this._isInitialized = true;
      this._setUCLang();
    });

    this.translateService.onLangChange.subscribe(() => {
      this._setUCLang();
    });

    this._setUCLang(); // Set initial language
  }

  private _setUCLang() {
    if (usercentrics && this._isInitialized) {
      if (usercentrics.changeLanguage && this.translateService.currentLang) {
        const langShort = this.translateService.currentLang.split('-')[0];
        usercentrics.changeLanguage(langShort);
      }
    }
  }

  public getSettings() {
    if (usercentrics) {
      return usercentrics.getSettings();
    } else {
      return null;
    }
  }

  public consentedTo(serviceKey: CONSENT_SERVICES) {
    return this._consents[serviceKey];
  }

  public async consentTo(consentService: CONSENT_SERVICES) {
    if (!window.UC_UI) return;

    const id: string = window.UC_UI.getServicesBaseInfo().find((service: any) => service.name === consentService)?.id;
    id && (await window.UC_UI.acceptService(id));
  }

  public showInfoModal() {
    this.router.navigate(['legal/settings']);
  }

  public getGoogleMapsBackgroundImageUrl(latitude: number, longitude: number, zoom: number): string {
    return `https://privacy-proxy-server.usercentrics.eu/googleMaps?center=${latitude},${longitude}&size=1120x560&zoom=${zoom}`;
  }

  public getYoutubeVideoBackgroundImageUrl(videoId: string): string {
    return `https://privacy-proxy-server.usercentrics.eu/video/youtube/${videoId}-poster-image`;
  }

  updateConsents(consentData: any) {
    const { event, type, ...newConsents } = consentData;
    this._consents = { ...this._consents, ...newConsents };
  }
}
