import { Injectable } from '@angular/core';
import { AppConfigService } from '@app/app-config.service';
import { FavoriteDataContextInterface } from '@modules/favorites/interfaces/favorite-data-context.interface';
import { MediaAudioInterface } from '@modules/media/models/media-audio.model';
import { MediaDocumentInterface } from '@modules/media/models/media-document.model';
import { MediaImageFormatInterface, MediaImageInterface } from '@modules/media/models/media-image.model';
import { MediaVideoInterface } from '@modules/media/models/media-video.model';
import { Screen } from '@modules/shared/services/window.service';
import { filetype } from 'src/helpers/strings';

import { MediaSetting } from '../domain/media-setting.interface';

interface ImageFormatSetting {
  id: string;
  filemime: string;
  filename: string;
  filesize: number;
  width: number;
  height: number;
  ratio: number;
}
export interface MediaAlertnateImageFormatResponse {
  id: string;
  type: string;
  key: string;
  mime_type: string;
  filemime: string;
  filename: string;
  filesize: number;
  height: number;
  width: number;
  image?: {
    id: string;
    type: string;
    filemime: string;
    filename: string;
    filesize: number;
    uri: {
      url: string;
      value: string;
    };
    relationships: {
      meta: {
        height: number;
        width: number;
      };
    };
  };
  relationships: {
    image: {
      id: string;
      type: string;
      meta: {
        width: number;
        height: number;
      };
    };
  };
}
export interface MediaImageFormatResponse {
  id: string;
  type: string;
  filemime: string;
  filename: string;
  filesize: number;
  uri: {
    url: string;
    value: string;
  };
  relationships: {
    meta: ImageMetaResponse;
  };
}
export interface MediaImageResponse {
  id: string;
  mars_shelf_number?: string | null;
  mars_origin_id?: string | null;
  title: string;
  type: string;
  name: string;
  image: MediaImageFormatResponse;
  adjusted_image?: MediaImageFormatResponse;
  alternate_images: MediaAlertnateImageFormatResponse[];
  caption: string;
  description: string;
  display_date?: string;
  height?: number;
  width?: number;
  mime_type?: string;
  filemime?: string;
  filename?: string;
  filesize?: number;
  fuel_label_text?: { processed?: string };
  relationships: {
    image: {
      id: string;
      meta: ImageMetaResponse;
    };
    adjusted_image?: {
      id: string;
      meta: ImageMetaResponse;
    };
  };
}

export interface ImageMetaResponse {
  alt: string;
  title: string;
  height: number;
  width: number;
}

export interface MediaDocumentResponse {
  id: string;
  mars_shelf_number?: string | null;
  mars_origin_id?: string | null;
  name: string;
  type: string;
  caption: string;
  description: string;
  display_date?: string;
  document_page_count: number;
  fuel_label_text?: { processed?: string };
  file: {
    id: string;
    filemime: string;
    filename: string;
    filesize: number;
    uri: {
      url: string;
      value: string;
    };
  }[];
}

export interface MediaAudioResponse {
  id: string;
  mars_shelf_number?: string | null;
  mars_origin_id?: string | null;
  name: string;
  type: string;
  caption: string;
  description: string;
  display_date?: string;
  fuel_label_text?: { processed?: string };
  audio: {
    id: string;
    filename: string;
    filemime: string;
    filesize: number;
    uri: {
      url: string;
      value: string;
    };
    meta: {
      playing_time: number;
    };
  }[];
}

export interface MediaVideoResponse {
  id: string;
  mars_shelf_number?: string | null;
  mars_origin_id?: string | null;
  name: string;
  type: string;
  caption: string;
  description: string;
  display_date?: string;
  poster_image: MediaImageFormatResponse;
  fuel_label_text?: { processed?: string };
  video: {
    id: string;
    filename: string;
    filemime: string;
    filesize: number;
    playing_time: number;
    meta: {
      width: number;
      height: number;
      key: string;
      playing_time: number;
    };
    uri: {
      url: string;
      value: string;
    };
  }[];
}

// TODO: Split this, so we can implement the Adapater Interface?
@Injectable({
  providedIn: 'root',
})
export class MediaAdapter {
  private adapter!: MediaAdapterClass;
  constructor(private configService: AppConfigService) {
    this.adapter = new MediaAdapterClass(configService);
  }

  public parseStaticImage(url: string): string {
    return `${this.configService.getConfig().apiBaseUrl}/${url}`;
  }

  public parse(
    data: any,
    mediaSetting: MediaSetting[] = [],
    context?: FavoriteDataContextInterface,
    slug = ''
  ): MediaImageInterface | MediaVideoInterface | MediaDocumentInterface | MediaAudioInterface {
    return this.adapter.parse(data, mediaSetting, context, slug);
  }

  public parseStructuredImage(
    data: MediaImageResponse,
    mediaSetting: MediaSetting[],
    context: FavoriteDataContextInterface | null,
    slug = ''
  ): MediaImageInterface {
    return this.adapter.parseStructuredImage(data, mediaSetting, context, slug);
  }

  public parseImage(
    data: MediaImageResponse,
    mediaSetting: MediaSetting[],
    context: FavoriteDataContextInterface | null,
    slug = ''
  ): MediaImageInterface {
    return this.adapter.parseImage(data, mediaSetting, context, slug);
  }

  public parseDocument(
    data: MediaDocumentResponse,
    context: FavoriteDataContextInterface | null,
    slug = ''
  ): MediaDocumentInterface {
    return this.adapter.parseDocument(data, context, slug);
  }

  public parseAudio(
    data: MediaAudioResponse,
    context: FavoriteDataContextInterface | null,
    slug = ''
  ): MediaAudioInterface {
    return this.adapter.parseAudio(data, context, slug);
  }

  public parseVideo(
    data: MediaVideoResponse,
    context: FavoriteDataContextInterface | null,
    slug = ''
  ): MediaVideoInterface {
    return this.adapter.parseVideo(data, context, slug);
  }
}

export class MediaAdapterClass {
  constructor(private configService: AppConfigService) {}

  public parse(
    data: any,
    mediaSetting: MediaSetting[] = [],
    context?: FavoriteDataContextInterface,
    slug = ''
  ): MediaImageInterface | MediaVideoInterface | MediaDocumentInterface | MediaAudioInterface {
    const mediaContext = context || null;
    switch (data.type) {
      case 'structured_image':
        return this.parseStructuredImage(data, mediaSetting, mediaContext, slug);

      case 'image':
        return this.parseImage(data, mediaSetting, mediaContext, slug);

      case 'document':
        return this.parseDocument(data, mediaContext, slug);

      case 'audio':
        return this.parseAudio(data, mediaContext, slug);

      case 'video':
        return this.parseVideo(data, mediaContext, slug);
    }

    return this.parseImage(data, mediaSetting, mediaContext, slug);
  }

  public parseStructuredImage(
    data: MediaImageResponse,
    mediaSetting: MediaSetting[],
    context: FavoriteDataContextInterface | null,
    slug = ''
  ): MediaImageInterface {
    // Use performance adjusted image if available
    data.image = data.adjusted_image ?? data.image;
    data.relationships.image = data.relationships.adjusted_image ?? data.relationships.image;

    if (!mediaSetting || !mediaSetting.length) {
      mediaSetting = this.getDefaultMediaSetting(data.relationships.image?.meta);
    }
    return {
      id: data.id,
      marsShelfNumber: data.mars_shelf_number ?? null,
      marsOriginId: data.mars_origin_id ?? null,
      mediaType: 'image',
      name: data.name,
      filename: data.filename,
      filesize: data.filesize,
      width: data.width,
      height: data.height,
      type: data.type,
      mimeType: data.mime_type,
      caption: data.caption,
      description: data.description,
      formats: this.parseImageFormats(data, mediaSetting),
      lightboxFormats: this.parseImageFormats(data, this.getMediaLightboxImageSetting(data)),
      favoriteData: {
        id: data.id,
        type: 'structured_image',
        context,
      },
      shareData: {
        title: data.title,
        content: data.name,
        url: this.getSharingUrl(data, context, slug),
      },
      downloadData: data.image
        ? {
            id: data.image.id,
            type: 'file', //'structured_image',
            fileType: 'image',
            format: 'JPG',
            title: data.name,
          }
        : undefined,
    };
  }

  public parseImage(
    data: MediaImageResponse,
    mediaSetting: MediaSetting[],
    context: FavoriteDataContextInterface | null,
    slug = ''
  ): MediaImageInterface {
    // Use performance adjusted image if available
    data.image = data.adjusted_image ?? data.image;
    data.relationships.image = data.relationships.adjusted_image ?? data.relationships.image;

    if (!mediaSetting || !mediaSetting.length) {
      mediaSetting = this.getDefaultMediaSetting(data.relationships.image?.meta);
    }
    return {
      id: data.id,
      type: data.type,
      marsShelfNumber: data.mars_shelf_number ?? null,
      marsOriginId: data.mars_origin_id ?? null,
      mediaType: 'image',
      name: data.name,
      filename: data.filename,
      filesize: data.filesize,
      width: data.width,
      height: data.height,
      caption: data.caption,
      description: data.description,
      displayDate: data.display_date ? new Date(data.display_date) : undefined,
      formats: this.parseImageFormats(data, mediaSetting),
      lightboxFormats: this.parseImageFormats(data, this.getMediaLightboxImageSetting(data)),
      fuelLabel: data.fuel_label_text?.processed,
      favoriteData: {
        id: data.id,
        type: 'image',
        context,
      },
      shareData: {
        title: data.title,
        content: data.name,
        url: this.getSharingUrl(data, context, slug),
      },
      downloadData: {
        id: data.id,
        type: 'media', // data.type,
        fileType: 'image',
        format: 'JPG',
        title: data.name,
      },
    };
  }

  private parseImageFormats(data: MediaImageResponse, mediaSettings: MediaSetting[]): MediaImageFormatInterface[] {
    if (!mediaSettings || !mediaSettings.length) {
      mediaSettings = this.getDefaultMediaSetting(data.relationships.image.meta);
    }
    // sort the images by size, because the reducer will select the first matching image
    const sortedImages = this.combineBaseImages(data).sort((a, b) => a.filesize - b.filesize);

    const findImageWithClosestRatio = (images: ImageFormatSetting[], ratio: number) => {
      // find closest ratio (selects the first match, ratios are rounded to 2 decimals)
      const format = images.reduce((closest: ImageFormatSetting, current: ImageFormatSetting) => {
        if (!closest) {
          closest = current;
        }
        return Math.abs(current.ratio - ratio) < Math.abs(closest.ratio - ratio) ? current : closest;
      });
      return format;
    };

    // Note: for this to work, the images need to be sorted by filesize
    const selectImageByRatioAndSize = (images: ImageFormatSetting[], setting: MediaSetting): ImageFormatSetting => {
      // filter out base images that are too small
      const largeEnoughImages = images.filter(
        (image) => image.width >= setting.width && image.height >= setting.height
      );
      if (largeEnoughImages.length) {
        return findImageWithClosestRatio(largeEnoughImages, setting.ratio);
      } else {
        // choose any if no large enough image is available
        return findImageWithClosestRatio(images, setting.ratio);
      }
    };

    return mediaSettings.map((setting) => {
      const image = selectImageByRatioAndSize(sortedImages, setting);
      return {
        id: image.id,
        height: image.height,
        width: image.width,
        mimeType: image.filemime,
        filename: image.filename,
        minWidth: setting.screen as unknown as string,
        url: `${this.configService.getConfig().mediaDeliveryUrl}/${image.id}/${setting.key}`,
        landscapeOnly: setting.landscapeOnly,
        filesize: image.filesize,
      };
    });
  }

  public parseDocument(
    data: MediaDocumentResponse,
    context: FavoriteDataContextInterface | null,
    slug = ''
  ): MediaDocumentInterface {
    return {
      id: data.id,
      marsShelfNumber: data.mars_shelf_number ?? null,
      marsOriginId: data.mars_origin_id ?? null,
      title: data.name,
      caption: data.caption,
      displayDate: data.display_date ? new Date(data.display_date) : undefined,
      type: 'document',
      mediaType: 'document',
      pageCount: data.document_page_count,
      fuelLabel: data.fuel_label_text?.processed,
      formats: data.file.map((file) => {
        return {
          id: file.id,
          mimeType: file.filemime,
          name: file.filename,
          url: this.parseUrl(file.uri.url),
          filesize: file.filesize,
        };
      }),
      favoriteData: {
        id: data.id,
        type: 'document',
        context,
      },
      shareData: {
        title: data.name,
        content: '',

        url: this.getSharingUrl(data, context, slug),
      },
      downloadData: data.file?.length
        ? {
            id: data.id,
            type: 'media', // data.type,
            fileType: 'document',
            format: filetype(data.file[0].filename),
            title: data.name,
          }
        : undefined,
    };
  }

  public parseAudio(
    data: MediaAudioResponse,
    context: FavoriteDataContextInterface | null,
    slug = ''
  ): MediaAudioInterface {
    return {
      id: data.id,
      marsShelfNumber: data.mars_shelf_number ?? null,
      marsOriginId: data.mars_origin_id ?? null,
      title: data.name,
      mediaType: 'audio',
      caption: data.caption,
      description: data.description,
      displayDate: data.display_date ? new Date(data.display_date) : undefined,
      fuelLabel: data.fuel_label_text?.processed,
      formats: data.audio.map((file) => {
        return {
          id: file.id,
          mimeType: file.filemime,
          name: file.filename,
          extension: file.filename.split('.').pop() || '',
          url: this.parseUrl(file.uri.url),
          filesize: file.filesize,
          duration: file.meta?.playing_time,
        };
      }),
      favoriteData: {
        id: data.id,
        type: 'audio',
        context,
      },
      shareData: {
        title: data.name,
        content: '',

        url: this.getSharingUrl(data, context, slug),
      },
      downloadData: data.audio?.length
        ? {
            id: data.id,
            type: 'media', // data.type,
            fileType: 'audio',
            format: data.audio[0].filemime.split('/').pop() as string,
            title: data.name,
          }
        : undefined,
    };
  }

  public parseVideo(
    data: MediaVideoResponse,
    context: FavoriteDataContextInterface | null,
    slug = ''
  ): MediaVideoInterface {
    return {
      id: data.id,
      marsShelfNumber: data.mars_shelf_number ?? null,
      marsOriginId: data.mars_origin_id ?? null,
      title: data.name,
      mediaType: 'video',
      caption: data.caption,
      description: data.description,
      displayDate: data.display_date ? new Date(data.display_date) : undefined,
      poster: data.poster_image ? `${this.configService.getConfig().mediaDeliveryUrl}/${data.poster_image.id}/768` : '',
      fuelLabel: data.fuel_label_text?.processed,
      formats: data.video.map((file) => {
        return {
          id: file.id,
          key: file.meta.key,
          url: this.parseUrl(file.uri.url),
          name: file.filename,
          mimeType: file.filemime,
          height: file.meta.height || 900,
          width: file.meta.width || 1600,
          extension: file.filename.split('.').pop() || '',
          duration: file.meta?.playing_time,
          filesize: file.filesize,
        };
      }),
      favoriteData: {
        id: data.id,
        type: 'video',
        context,
      },
      shareData: {
        title: data.name,
        content: '',

        url: this.getSharingUrl(data, context, slug),
      },
      downloadData: data.video?.length
        ? {
            id: data.id,
            type: 'media', // data.type,
            fileType: 'video',
            format: data.video[0].filemime.split('/').pop() as string,
            title: data.name,
          }
        : undefined,
    };
  }

  private parseUrl(url: string) {
    if (url.startsWith('/')) {
      return `${this.configService.getConfig().apiBaseUrl}${url}`;
    }

    return url;
  }

  private getDefaultMediaSetting(meta: ImageMetaResponse) {
    const ratioX = meta.height / meta.width;
    const ratioY = meta.width / meta.height;
    return [200, 300, 400, 500, 600].map((width) => {
      const _setting: MediaSetting = {
        height: ratioX * width,
        width: width,
        ratio: this.roundRatio(ratioY),
        key: width,
        screen: width,
      };
      return _setting;
    });
  }

  private roundRatio(ratio: number) {
    return Math.round((ratio + Number.EPSILON) * Math.pow(10, 2)) / Math.pow(10, 2);
  }

  private combineBaseImages(data: MediaImageResponse) {
    let images: ImageFormatSetting[] = [];

    if (data.image?.id || data.relationships.image?.id) {
      const width = data.width || data.relationships.image?.meta.width || 0;
      const height = data.height || data.relationships.image?.meta.height || 0;
      images.push({
        id: data.image?.id || data.relationships.image.id,
        filemime: data.mime_type || data.filemime || 'image/jpeg',
        filename: data.filename || '',
        filesize: data.filesize || 0,
        width: width,
        height: height,
        ratio: this.roundRatio(width / height),
      });
    }

    if (data.alternate_images?.length) {
      images = images.concat(
        data.alternate_images.map((alternateImage) => {
          const image = alternateImage.image;
          const imageRelationship = alternateImage.relationships.image;
          const height =
            alternateImage.height || image?.relationships.meta.height || imageRelationship.meta.height || 0;
          const width = alternateImage.width || image?.relationships.meta.width || imageRelationship.meta.width || 0;
          return {
            id: imageRelationship.id,
            filemime: alternateImage.mime_type || image?.filemime || 'image/jpeg',
            filename: alternateImage.filename || image?.filename || '',
            filesize: alternateImage.filesize || image?.filesize || 0,
            width,
            height,
            ratio: this.roundRatio(width / height),
          };
        })
      );
    }

    return images;
  }

  private getMediaLightboxImageSetting(data: MediaImageResponse): MediaSetting[] {
    const meta = data.relationships.image.meta;
    const ratioX = meta.height / meta.width;
    const ratioY = meta.width / meta.height;
    return [
      {
        screen: Screen.XXXS,
        width: 375,
      },
      {
        screen: Screen.XXS,
        width: 568,
      },
      {
        screen: Screen.XS,
        width: 768,
      },
      {
        screen: Screen.SM,
        width: 1024,
      },
      {
        screen: Screen.LG,
        width: 1600,
      },
    ].map(({ screen, width }) => {
      const _setting: MediaSetting = {
        height: ratioX * width,
        width: width,
        ratio: this.roundRatio(ratioY),
        key: width,
        screen: screen,
      };

      return _setting;
    });
  }

  private getSharingUrl(data: any, context: any, slug = '') {
    if (!context) {
      return `${location.origin}/(lightbox:${data.type}/${data.id})`;
    }
    if (context.type === 'articles' || context.type === 'article') {
      return `${location.origin}/article/${context.id}/(lightbox:${data.type}/${data.id})`;
    } else if (context.type === 'presskits' || context.type === 'presskit') {
      return `${location.origin}/press-kit/${context.id}/(lightbox:${data.type}/${data.id})`;
    } else if (context.type === 'page') {
      return `${location.origin}${slug}/(lightbox:${data.type}/${data.id})`;
    } else if (context.type === 'content_hub') {
      return `${location.origin}/content${slug}/(lightbox:${data.type}/${data.id})`;
    } else if (context.type === 'topic_page') {
      return `${location.origin}${slug}/(lightbox:${data.type}/${data.id})`;
    }
    return `${location.origin}/(lightbox:${data.type}/${data.id})`;
  }
}
