import { Component, HostBinding } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AppConfigService } from '@app/app-config.service';
import { PreMediaService } from '@app/features/pre-media/pre-media.service';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ConsentService } from '@modules/consent/consent.service';
import { FavoritesContentService } from '@modules/favorites/services/favorites-content.service';
import { FavoritesService } from '@modules/favorites/services/favorites.service';
import { EventsService } from '@modules/shared/services/events.service';
import { GlobalGTMEventsService } from '@modules/shared/services/global-gtm-events.service';
import { ScrollLockService } from '@modules/shared/services/scroll-lock.service';
import { ScrollToLastPositionService } from '@modules/shared/services/scroll-to-last-position.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { languageConfig } from 'src/environments/language.config';

import { AppService } from './app.service';

@Component({
  selector: 'mb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @HostBinding('class.floating-subnav')
  public floatingSubnav = false;

  @HostBinding('class.light-page')
  public isLigthPage = false;

  // only for testing frontend alignments
  public showGrid = false;
  public gridClass: string[] = ['container-xxl', 'container-sm'];
  public activeGridClass = 'container-sm';

  public favCount$: Observable<number>;
  public notificationCount$: Observable<number>;
  public favoritesOpen = false;
  public isLoading$: Observable<boolean>;

  public languageOptions: { label: string; value: string }[];
  public hasPreMedia$: Observable<boolean>;
  public preMediaCount$: Observable<number>;

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private consentService: ConsentService,
    private scrollHistoryService: ScrollToLastPositionService,
    private favoritesService: FavoritesService,
    private favoritesContentService: FavoritesContentService,
    private appService: AppService,
    private preMediaService: PreMediaService,
    private eventsService: EventsService,
    private scrollLockService: ScrollLockService,
    private globalGTMEventsService: GlobalGTMEventsService,
    private configService: AppConfigService
  ) {
    const angularPlugin = new AngularPlugin();
    const connectionString = this.configService.getConfig().connectionString;

    this.scrollHistoryService.handleScrollPositionRestoration();
    this.consentService.listenToConsentEvents();
    this.globalGTMEventsService.handleGlobalGTMEvents();

    this.preMediaCount$ = this.preMediaService.preMediaCount$;
    this.hasPreMedia$ = this.preMediaService.hasPreMedia$;
    this.favoritesService.favoritesOpen$.subscribe((isOpen) => {
      this.favoritesOpen = isOpen;
      if (!isOpen) {
        this.scrollLockService.unlock();
      }
    });

    this.isLoading$ = this.appService.isLoading$;
    this.favCount$ = this.favoritesContentService.favoritesCount$;

    this.notificationCount$ = combineLatest(
      [this.preMediaCount$, this.eventsService.pendingRegistrationsCount$],
      (preMediaCount, pendingRegistrations) => {
        return preMediaCount + pendingRegistrations;
      }
    );

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.favoritesOpen = false;
      }
    });

    this.translateService.addLangs(languageConfig.languages);
    this.languageOptions = this.translateService.getLangs().map((lang) => {
      return {
        label: lang,
        value: lang,
      };
    });

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateHTMLLangAttribute(event.lang);
      this.storeLanguageInLocalStorage(event.lang);
    });
    const appLanguage = this.determineApplicationLanguage(languageConfig.defaultLanguage);
    this.translateService.use(appLanguage);

    this.appService.isLightPage$.subscribe((isLightPage) => {
      this.isLigthPage = isLightPage;
    });

    const appInsights = new ApplicationInsights({
      config: {
        connectionString,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router },
        },
      },
    });
    if (connectionString) {
      try {
        appInsights.loadAppInsights();
        appInsights.addTelemetryInitializer((envelope) => {
          if (envelope && envelope.tags) {
            envelope.tags['ai.cloud.role'] = 'mbmedia_frontend';
          }
        });
      } catch (error) {
        console.error(`Application Insight failed to load ${error}`);
      }
    }
  }

  private updateHTMLLangAttribute(lang: string): void {
    document.documentElement.lang = lang;
  }

  private determineApplicationLanguage(defaultLanguage: string): string {
    // set initial current language
    let usedLanguage = defaultLanguage;
    // when language is changed store it in localStorage
    if (localStorage && localStorage.getItem('lang')) {
      const localStorageLang = localStorage.getItem('lang');
      if (localStorageLang && languageConfig.languages.includes(localStorageLang)) {
        usedLanguage = localStorageLang;
      }
    } else if (languageConfig.autoDetectLanguage) {
      const browserLang = this.translateService.getBrowserCultureLang();
      if (browserLang) {
        if (languageConfig.languages.includes(browserLang)) {
          usedLanguage = browserLang;
        } else {
          switch (browserLang.split('-')[0]) {
            case 'de':
              usedLanguage = 'de-DE';
              break;
            case 'en':
              usedLanguage = 'en-US';
              break;
            default:
            // falls back to configured defaultLanguage
          }
        }
      }
    }

    return usedLanguage;
  }

  public storeLanguageInLocalStorage(lang: string) {
    try {
      if (localStorage) {
        localStorage.setItem('lang', lang);
      }
    } catch (e) {
      // IMPROVE: send sentry event here
    }
  }

  public changeLang(lang: string) {
    this.translateService.use(lang);
  }

  // IMPROVE: favorite service should close itself
  public closeFavorites() {
    this.scrollLockService.unlock();
    this.favoritesService.closeFavorites();
  }
}
